console.log("booking-channel js loaded");

import consumer from "./consumer"


$(function() {
  $('[data-channel-subscribe="booking"]').each(function(index, element) {
    var $element = $(element),
        booking_id = $element.data('booking-id')
   //     messageTemplate = $('[data-role="message-template"]');

  //  $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)        

    //App.cable.subscriptions.create(
    consumer.subscriptions.create(
      {
        channel: "BookingChannel",
        booking: booking_id
      },
      {
        received: function(data) {
         // var content = messageTemplate.children().clone(true, true);
         if (data.users_count){
         document.getElementById("streamming-users").innerHTML = data.users_count;
         }
         if (data.phase){
         document.getElementById("breath-phase").innerHTML = data.phase;
         };
         if (data.psi_value){
           document.getElementById("psi-value").innerHTML = data.psi_value;
         }
         
         if (data.pro_value){
           document.getElementById("pro-value").innerHTML = data.pro_value;
         }
         if (data.score_value){
           document.getElementById("score-value").innerHTML = data.score_value;
         }
         
         if (data.my_avatar){
           var avas = $("#avas").val();
           $("#avas").val(avas +","+data.my_avatar);
         }
    
         
      //    content.find('[data-role="message-text"]').text(data.body);
     //     $element.append(content);
          $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});


