import $ from 'jquery';


$(document).on('turbolinks:load', function() {
    
    if($('.sessions-data').length > 0){
        $(document).on("click", ".sessions-title", function(){
            var parent = $(this).parents(".sessions-data");
            var table = parent.find(".sessions-table");
            table.slideToggle(200);
        });
    };
    
    if($('.sessions-title.results').length > 0){
      $(document).on("click", ".sessions-title.results", function(){
            var parent = $(this).parents(".sessions-data");
            var bookingId = $(this)[0].dataset.booking;
            var experimentId = $(this)[0].dataset.experiment;
            var loaded = parent.find(".loaded");
            if (loaded.length == 0){
                console.log("loading");
                $.ajax({
                    url:  '/experiment_results?booking_id='+bookingId+"&experiment_id="+experimentId,
                    type: "GET"
                });
            }
        });
    };
    
    if($('.sessions-title.user-sessions').length > 0){
      $(document).on("click", ".sessions-title.user-sessions", function(){
            var parent = $(this).parents(".sessions-data");
            var bookingId = $(this)[0].dataset.booking;
            var experimentId = $(this)[0].dataset.experiment;
            var loaded = parent.find(".loaded");
            if (loaded.length == 0){
                console.log("loading");
                $.ajax({
                    url:  '/experiment_sessions?booking_id='+bookingId+"&experiment_id="+experimentId,
                    type: "GET"
                });
            }
        });
    };
    
});