import $ from 'jquery';


$(document).on('turbolinks:load', function() {
   
    var refreshProgress;
    
   
        
        $(document).on("click", ".start-play", function(){

            resetStates();
            if(window.audio != undefined){
              window.audio.pause();
            };
            var songUrl = $(this)[0].dataset.url;
            window.audio = new Audio(songUrl);
            window.audio.play();
            $(this).hide();
            var parent = $(this).parents(".play-controls");
            var pauseBtn = parent.find(".stop-song");
            var progressBar = parent.find(".song-progress");
            var timePassed = parent.find(".song-time-passed");
            pauseBtn.show();
            progressBar.show();
            timePassed.show();
            
            refreshProgress = setInterval(function(){
              timePassed.html(secondsTimeSpanToHMS(parseInt(window.audio.currentTime)));
              
              progressBar.val((window.audio.currentTime/window.audio.duration)*100);
            }, 1000);
            
        });
        
        $(document).on("click", ".stop-song", function(){
            resetStates();
            if(window.audio != undefined){
              window.audio.pause();
            };
        });
        
        function resetStates(){
            clearInterval(refreshProgress);
            $(".stop-song").hide();
            $(".start-play").show();
            $(".song-progress").hide();
            $(".song-time-passed").hide();
        }
        
        $(document).on("change", ".song-progress", function(){
           var duration = window.audio.duration;
           var position = $(this).val()/100;
           window.audio.currentTime = duration*position;
        });
        
 

  
    
    
    function secondsTimeSpanToHMS(s) {  
      var h = Math.floor(s / 3600); //Get whole hours
      s -= h * 3600;
      var m = Math.floor(s / 60); //Get remaining minutes
      s -= m * 60;
    
      if (h < 1) {
        return (m < 10 ? '0' + m : m) + ":" + (s < 10 ? '0' + s : s); //zero padding on minutes and seconds
      }else{
        return (h < 10 ? '0' + h : h) + ":" + (m < 10 ? '0' + m : m) + ":" + (s < 10 ? '0' + s : s);
      };
    }
});

document.addEventListener('turbolinks:before-render', () => {
  if(window.audio != undefined){
      window.audio.pause();
   };
   
 
   $(".modal").modal('hide');
})