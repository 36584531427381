console.log("experiment-channel js loaded");

import consumer from "./consumer"


$(function() {
  $('[data-channel-subscribe="experiment"]').each(function(index, element) {
    var $element = $(element),
        experiment_id = $element.data('experiment-id'),
        messageTemplate = $('[data-role="message-template"]');


 //   $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)        

    //App.cable.subscriptions.create(
    consumer.subscriptions.create(
      {
        channel: "ExperimentChannel",
        experiment: experiment_id
      },
      {
        received: function(data) {
         // var content = messageTemplate.children().clone(true, true);

         document.getElementById("comments-size").innerHTML = data.comments_size;
          
          var content = messageTemplate.children().clone(true, true);
          var inserter = $("#inseart-comment");
          
          content.find('[data-role="user-avatar"]').attr('src', data.avatar_url);
          
          content.find('[data-role="message-delete"]').attr('href', "/comments/" + data.comment_id);
          content.find('[data-role="message-delete"]').attr('onclick', "delete_comment()" + data.comment_id);
          
          content.find('[data-role="message-reply"]').attr('href', " /comment_reply?id=" + data.comment_id);
         
          
          content.find('[data-role="message-body"]').text(data.message);
          content.find('[data-role="message-user-name"]').text(data.user_name);
          content.find('[data-role="message-created-at"]').text(data.created_at);
          inserter.prepend(content);
       //   $inserter.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);

        }
      }
    );
  });
});


